import { useState, useEffect, useCallback, useMemo } from "react";

import Card from "../../../shared/card/card"
import SingleSelectDropdown from "components/ui/singleSelectDropdown";
import LineChart from "../../../shared/charts/line/line-chart";

import { getDateMonthsAgo } from "../../utils"

import { useAtom } from "jotai";
import { weekIndexAtom, weeksAtom } from "../../filters/main-filters";
import categories from '../../categories.json';

export default function History({data}) {

    const [weeks] = useAtom(weeksAtom)
    const [currentWeekIndex] = useAtom(weekIndexAtom)

    const timeframes = useMemo(() => ({
        3: "last 3 months",
        6: "last 6 months",
        12: "last 12 months",
    }), []);
    const [activeTimeframe, setActiveTimeframe] = useState(Object.keys(timeframes)[0])

    const categoryMap = useMemo(() => (
        Object.fromEntries(categories.map((category) => [category.name, category.title]))
    ), []);
    const [activeCategory, setActiveCategory] = useState(Object.keys(categoryMap)[0])
    
    const activeColor = categories.find(({name}) => name === activeCategory).color
    
    // State management for historical chart data
    const [countHistory, setCountHistory] = useState([]);
        
    // Compute the data for the historical chart
    const computeCountHistory = useCallback((activeWeekString, activeCategory) => {

        // Filter data to include only items between today and months ago
        const activeWeek = new Date(activeWeekString)
        const monthsAgo = getDateMonthsAgo(activeWeek, Number(activeTimeframe));
        const filteredDates = data.filter(item => {
            const date = new Date(item.week);
            return date >= monthsAgo && date <= activeWeek;
        });

        // Get a list of all unique weeks from filteredDates
        const allWeeks = [...new Set(filteredDates.map(item => item.week))];

        // Initialize the result with all weeks, setting count to 0
        const result = allWeeks.map(week => ({
            week: week,
            count: 0
        }));

        // Filter ratingsData to include only items with the specified engagement level
        const filteredData = data.filter(item => item.categories[activeCategory]);

        // Populate the counts for the active category
        filteredData.forEach(item => {
            const week = item.week;
            const existingEntry = result.find(entry => entry.week === week);

            if (existingEntry) {
                existingEntry.count += 1;
            }
        });

        console.log(`History Array: ${result}`); // Log the result to the console
        return result; // Return the final aggregated array
    }, [data, activeTimeframe]);

    // recompute history data when ratings data, active level, or active timeframe is updated
    useEffect(() => {
        setCountHistory(computeCountHistory(weeks[currentWeekIndex], activeCategory))
    }, [computeCountHistory, currentWeekIndex, weeks, activeCategory])

    // Handler functions for setting active options
    const handleTimeframeChange = (option) => setActiveTimeframe(option);
    const handleCategoryChange = (option) => setActiveCategory(option);

    return (
        <Card
            title = {
                <>
                    <SingleSelectDropdown 
                        options={categoryMap} 
                        activeOption={activeCategory} 
                        changeHandler={handleCategoryChange} 
                    /> 
                    {" employees over the "}
                    <SingleSelectDropdown 
                        options={timeframes} 
                        activeOption={activeTimeframe} 
                        changeHandler={handleTimeframeChange} 
                    />
                </>
            }
            chart= {
                <LineChart 
                    data={countHistory} 
                    label={` ${activeCategory}`.toLowerCase()} 
                    x="week" 
                    y="count" 
                    color={activeColor}
                /> 
            }
        />
    )
}