import { useEffect } from "react";
import useFetch from "../../../../utils/Clerk";
import { useAtom, atom } from "jotai";
import { MultiSelect } from "components/ui/multi-select";

const back_url = process.env.REACT_APP_API_URL;

export const levelAtom = atom(1)
export const activeTeamsAtom = atom([])
export const weekIndexAtom = atom(0)
export const weeksAtom = atom([])
export const teamsAtom = atom([])

const seniorityLabels = {
  '0': 'less than a year',
  '1': '1+ year',
  '2': '2+ years',
  '3': '3+ years',
  '4': '4+ years',
  '5': '5+ years',
  '6': '5+ years',
  '7': '5+ years',
  '8': '5+ years',
  '9': '5+ years',
  '10': '5+ years',
  '11': '5+ years'
}
export function FilterTeams({ activeOrganization }) {
    // const [visible, setVisible] = useState(false);
    // const [checkedTeams, setCheckedTeams] = useState([]);
    const [activeTeams, setActiveTeams] = useAtom(activeTeamsAtom);
    const [teams, setTeams] = useAtom(teamsAtom);
    const fetch = useFetch();

    // get teams data
    useEffect(() => {
      fetch(`${back_url}/v1/organizations/${activeOrganization}/teams`)
        .then(data => {
          console.log("Success!")
          setTeams(
            data.map(team => ({
              value: team.id,
              label: team.name
            }))
          )
        })
        .catch(error => console.error('Error fetching teams:', error))
    }, [fetch, activeOrganization, setTeams])
  
    return (
      <>
        <MultiSelect
          className="bg-white"
          options={teams}
          onValueChange={setActiveTeams}
          defaultValue={activeTeams}
          placeholder="Teams"
          maxCount={1}
        />
      </>
    )
  }

export function SeniorityFilter({ seniorities, activeSeniorities, setActiveSeniorities }) {
    return (
      <>
        <MultiSelect
          className="bg-white"
          options={seniorities.map((val) => ({value: val, label: seniorityLabels[val]}))}
          onValueChange={setActiveSeniorities}
          defaultValue={activeSeniorities}
          placeholder="Seniorities"
          maxCount={1}
        />
      </>
    )
  }

  export function LocationFilter({ locations, activeLocations, setActiveLocations }) {
    return (
      <>
        <MultiSelect
          className="bg-white"
          options={locations.map((val) => ({value: val, label: val}))}
          onValueChange={setActiveLocations}
          defaultValue={activeLocations}
          placeholder="Locations"
          maxCount={1}
        />
      </>
    )
  }

export function WeekSelector() {
  const [currentWeekIndex, setCurrentWeekIndex] = useAtom(weekIndexAtom);
  const [weeks] = useAtom(weeksAtom);

    // Week filtering
    const handlePrevWeek = () => {
      setCurrentWeekIndex((prevIndex) => (prevIndex < weeks.length - 1 ? prevIndex + 1 : prevIndex.length -  1));
    };
  
    const handleNextWeek = () => {
      setCurrentWeekIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
    };

  return (
    <div className="week-selector">
      <button className="arrow-button" onClick={handlePrevWeek}>{currentWeekIndex !== (weeks.length - 1) && '<'}</button>
      <span className="week-display">{weeks && weeks[currentWeekIndex]}</span>
      <button className="arrow-button" onClick={handleNextWeek}>{currentWeekIndex !== 0 && '>'}</button>
    </div>
  )
}
