import { useState, useMemo, useEffect, useCallback } from "react";

import Card from "pages/dashboard/shared/card/card"
import SingleSelectDropdown from "components/ui/singleSelectDropdown";
import Table from "components/ui/table";

import { useAtom } from "jotai";
import { weekIndexAtom, weeksAtom } from "pages/dashboard/main-dashboard/filters/main-filters";
import { teamsAtom } from "../../filters/main-filters";

import categories from '../../categories.json';

const seniorityLabels = {
    '0': 'less than a year',
    '1': '1+ year',
    '2': '2+ years',
    '3': '3+ years',
    '4': '4+ years',
    '5': '5+ years',
    '6': '5+ years',
    '7': '5+ years',
    '8': '5+ years',
    '9': '5+ years',
    '10': '5+ years',
    '11': '5+ years'
}

export default function Groups({data}) {

    const [weeks] = useAtom(weeksAtom)
    const [currentWeekIndex] = useAtom(weekIndexAtom)
    const [teams] = useAtom(teamsAtom);

    const groups = useMemo(() => ({
        "timezone": "location",
        "team_ids": "team",
        "seniority": "seniority"
    }), []);
    const [activeGroup, setActiveGroup] = useState(Object.keys(groups)[0])

    const categoryMap = useMemo(() => (
        Object.fromEntries(categories.map((category) => [category.name, category.title]))
    ), []);
    const [activeCategory, setActiveCategory] = useState(Object.keys(categoryMap)[0])

    // State management for table data (employee count by group and variation)
    const [tableData, setTableData] = useState({header:[], rows:[]})

    const computeTableData = useCallback((data, category, group, weekIndex) => {
        // Filter the data by the given week
        const currentWeek = weeks[weekIndex]
        const previousWeek = weeks[weekIndex+1]
        const filteredData = data.filter(item => item.week === currentWeek || item.week === previousWeek);
      
        // Reduce the filtered data to group by seniority and count isolated true entries
        const result = filteredData.reduce((acc, item) => {
            let groups = Array.isArray(item[group]) ? item[group] : [item[group]];
            console.log(groups)

            groups.forEach(groupItem => {
                if (!acc[groupItem]) {
                    acc[groupItem] = {
                        currentWeek: 0,
                        previousWeek: 0
                    };
                }
        
                if (item.categories[category] && item.week === currentWeek) {
                    acc[groupItem].currentWeek += 1;
                } 
                else if (item.categories[category] && item.week === previousWeek) {
                    acc[groupItem].previousWeek += 1;
                }

                // Calculate variation as percentage
                const previousWeekCount = acc[groupItem].previousWeek;
                const currentWeekCount = acc[groupItem].currentWeek;

                
                if (currentWeekCount - previousWeekCount === 0) {
                    acc[groupItem].variation = 0;
                } else if (previousWeekCount !== 0) {
                    acc[groupItem].variation = Math.round(((currentWeekCount - previousWeekCount) / previousWeekCount) * 100);
                } else {
                    acc[groupItem].variation = "∞";
                }
                
                // Determine arrow and format variation
                if (acc[groupItem].variation > 0) {
                    acc[groupItem].variationFormatted = `+${acc[groupItem].variation}% ↑`; // Up arrow
                } else if (acc[groupItem].variation < 0) {
                    acc[groupItem].variationFormatted = `${acc[groupItem].variation}% ↓`; // Down arrow
                } else if (acc[groupItem].variation === 0) {
                    acc[groupItem].variationFormatted = "-"; // No arrow for zero variation
                } else {
                    acc[groupItem].variationFormatted = `${acc[groupItem].variation} ↑`;
                }

            });

            return acc;
        }, {});
      
        return result;
    }, [weeks]);


    function formatTableData(labels, data) {
        const formattedData = {};
    
        for (let id in data) {
            if (labels.hasOwnProperty(id)) {
                const label = labels[id];
                formattedData[label] = data[id];
            }
        }

        return formattedData;
    }

    // recompute table data when ratings data, category, or grouping is updated
    useEffect(() => {
        const computedTableData = computeTableData(data, activeCategory, activeGroup, currentWeekIndex)
        let counter
        if (activeGroup === 'team_ids') {
            const teamsLabels = {}
            teams.map((team) => teamsLabels[team.value] = team.label)
            counter = formatTableData(teamsLabels, computedTableData);
        }
        else if (activeGroup === 'seniority') {
            counter = formatTableData(seniorityLabels, computedTableData)
        }
        else {
            counter = computedTableData
        }
        console.log(counter)
        setTableData({
            header:[groups[activeGroup].toUpperCase(), "COUNT", "WoW"],
            rows:Object.entries(counter).map(([group, { currentWeek, variationFormatted }]) => [group, currentWeek, variationFormatted])
        })
    //     setTableData(computeTableData(activeCategory, activeGroup))
    }, [data, activeCategory, groups, activeGroup, currentWeekIndex, teams, computeTableData, categoryMap])

    // Handler functions for setting active options
    const handleGroupChange = (option) => setActiveGroup(option);
    const handleCategoryChange = (option) => setActiveCategory(option);

    return (
        <Card 
            title = {
                <>
                    <SingleSelectDropdown 
                        options={categoryMap} 
                        activeOption={activeCategory} 
                        changeHandler={handleCategoryChange} 
                    /> 
                    {" employees by "}
                    <SingleSelectDropdown 
                        options={groups} 
                        activeOption={activeGroup} 
                        changeHandler={handleGroupChange} 
                    />
                </>
            }
            chart= {
                <Table 
                    header={tableData.header} 
                    rows={tableData.rows}
                />
            }
        />
    )
}